import { environment } from "src/environments/environment";
export class Url {
  // -------------- PHP API -------------- //

  static imageUrl: string = environment.Image_Base_Url;
  //Auth Api
  static Verify_Username: string = `${
    environment.Api_Base_Url + `${"v2/auth/user/verify"}`
  }`;
  static Login_URL: string = `${
    environment.Api_Base_Url + `${"v2/auth/user"}`
  }`;
  static Forgot_Password_Post: string = `${
    environment.Api_Base_Url + `${"v2/auth/user/forgot"}`
  }`;
  static Reset_Password_Post: string = `${
    environment.Api_Base_Url + `${"v2/auth/user/password/reset"}`
  }`;
  static Get_Login_Otp_Post: string = `${
    environment.Api_Base_Url + `${"v2/auth/user/get/otp"}`
  }`;
  static Login_With_OTP_Post: string = `${
    environment.Api_Base_Url + `${"v2/auth/user/verify/otp"}`
  }`;
  static User_Profile_URL: string = `${
    environment.Api_Base_Url + `${"users/profile"}`
  }`;
  //Tournament Api
  static Tournament_list__URL: string = `${
    environment.Api_Base_Url + `${"v2/user/tournament/list"}`
  }`;
  //Auction Api
  static Auction_list_URL: string = `${
    environment.Api_Base_Url + `${"v2/owner/auction/{tour_id}/list"}`
  }`;
  static Auction_Owner_Invitee_List: string = `${
    environment.Api_Base_Url + `${"v2/owner/auction/{tour_id}/invitees"}`
  }`;
  static Auction_Team_Player_Post_Add: string = `${
    environment.Api_Base_Url +
    `${"v2/auction/{tour_id}/team/{team_id}/players/{player_id}"}`
  }`;
  static Tournament_Player_Get_List: string = `${
    environment.Api_Base_Url + `${"v2/auction/{tour_id}/players/{auction_id}"}`
  }`;

  // -------------- JAVA API -------------- //

  // Auth API
  static INVITE_LINK_GET: string = `${
    environment.Java_Api_User_Base_Url +
    `${"v1/user/invite-details?link={link_id}"}`
  }`;
  static VERIFY_OTP: string = `${
    environment.Java_Api_User_Base_Url +
    `${"v1/auth/login/{encryptedId}/verify/{otp}"}`
  }`;
  static VERIFY_USER_GET: string = `${
    environment.Java_Api_User_Base_Url +
    `${"v1/auth/identity/{identity}/verify"}`
  }`;
  static REGISTER_URL: string = `${
    environment.Java_Api_Admin_Base_Url + `${"v1/auth/register"}`
  }`;
  static LOGIN_URL: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/auth/login"}`
  }`;
  static SENT_OTP: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/auth/login/{encryptedId}/otp"}`
  }`;

  // Dashboard
  static Player_Statistics_Get: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/user/stats"}`
  }`;
  static INVITATION_LIST_GET: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/user/invitations/received"}`
  }`;
  static INVITATION_STATUS_UPDATE: string = `${
    environment.Java_Api_User_Base_Url +
    `${"v1/t/{tour_id}/player/invite/{invite_id}/accept/{status}"}`
  }`;

  //Tournament Api
  static Tournament_list__GET: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/portal/tournament/list"}`
  }`;

  //Payment Api
  static SEND_PAYMENT: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/user/payment/capture"}`
  }`;
  static PAYMENT_ORDER: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/user/payment/order"}`
  }`;

  // User Api
  static USER_PROFILE_GET: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/user/profile"}`
  }`;
  static USER_CRICKETING_SKILLS_GET: string = `${
    environment.Java_Api_User_Base_Url + `${"v1/user/cric-profile"}`
  }`;
}
