import { DataServiceClass } from 'src/app/service/DataService.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../../service/http.service'
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Url } from 'src/app/_helpers/Url';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  private currentUserSubject: BehaviorSubject<any>;
  private currentUserDataSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;


  constructor(
    private http: HttpService,
    private router: Router,
    private dataServiceClass: DataServiceClass
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUserDataSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userData')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get currentUserDataValue(): any {
    return this.currentUserDataSubject.value;
  }

  verifyUsername(username) {
    let url = Url.VERIFY_USER_GET.replace('{identity}', username);
    return this.http.postData({}, url)
      .pipe(map(user => {
        return user;
      }));
  }

  login(data) {
    return this.http.postData(data, `${Url.LOGIN_URL}`)
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.removeItem('userData');
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  register(data) {
    return this.http.postData(data, `${Url.REGISTER_URL}`)
      .pipe(map(response => {
        localStorage.setItem('userData', JSON.stringify(response.data));
        this.currentUserDataSubject.next(response.data);
        return response;
      }));
  }

  forgotPassword(data) {
    return this.http.postData(data, `${Url.Forgot_Password_Post}`)
      .pipe(map(user => {
        return user;
      }));
  }

  resetPassword(data) {
    return this.http.postData(data, `${Url.Reset_Password_Post}`)
      .pipe(map(user => {
        return user;
      }));
  }

  getLoginOtp() {
    let url = Url.SENT_OTP.replace('{encryptedId}', this.dataServiceClass.userEncryptedIdGet);
    return this.http.postData({}, `${url}`)
      .pipe(map(user => {
        return user;
      }));
  }

  loginWithOtp(otp) {
    let url = Url.VERIFY_OTP.replace('{encryptedId}', this.dataServiceClass.userEncryptedIdGet).replace('{otp}', otp)
    return this.http.postData({}, `${url}`)
      .pipe(map(user => {
        this.dataServiceClass.userMobileNumberSet = null;
        localStorage.removeItem('userData');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  inviteLink(link_id) {
    let url = Url.INVITE_LINK_GET.replace('{link_id}', link_id);
    return this.http.getData(`${url}`)
      .pipe(map(response => {
        localStorage.setItem('userData', JSON.stringify(response.data));
        this.currentUserDataSubject.next(response.data);
        return response;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.dataServiceClass.clearDataService();
    this.router.navigate(['/auth/login']);
  }

}
