import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable()
export class DataServiceClass {
  private _encryptedId: any;
  private _userData: any;
  private _userMobileNumber: any;
  private _auctionDetail: any;
  private _tournamentDetail: any;
  private _teamData: any;
  private _lastTimeInvitee: any = null;
  private _lastTimeTeamPlayer: any = null;
  private _inviteePlayerListMap: Object = {};
  private _allTeamPlayerListMap: Object = {};
  private _returnToAuctionFlag: boolean = true;
  private onDestroy$ = new Subject<boolean>();
  constructor() {
    this.auctionDetailObj();
    this.tournamentDetailObj();
  }
  // userMobileNumber stores
  public get userMobileNumberGet(): any {
    return this._userMobileNumber;
  }
  public set userMobileNumberSet(value: any) {
    this._userMobileNumber = value;
  }
  // user encryptedId
  public get userEncryptedIdGet(): any {
    return this._encryptedId;
  }
  public set userEncryptedIdSet(value: any) {
    this._encryptedId = value;
  }
  // user data
  public get userDataGet(): any {
    return this._userData;
  }
  public set userDataSet(value: any) {
    this._userData = value;
  }
  //Invitee playerlist map 
  public get inviteePlayerListMapGet(): any {
    return this._inviteePlayerListMap;
  }
  public set inviteePlayerListMapSet(value: any) {
    this._inviteePlayerListMap = value;
  }
  //All team player map
  public get allTeamPlayerListMapGet(): any {
    return this._allTeamPlayerListMap;
  }
  public set allTeamPlayerListMapSet(value: any) {
    this._allTeamPlayerListMap = value;
  }
  //Last time var for invitee
  public get lastTimeInviteeGet(): any {
    return this._lastTimeInvitee;
  }
  public set lastTimeInviteeSet(value: any) {
    this._lastTimeInvitee = value;
  }
  //Last time var for Team player
  public get lastTimeTeamPlayerGet(): any {
    return this._lastTimeTeamPlayer;
  }
  public set lastTimeTeamPlayerSet(value: any) {
    this._lastTimeTeamPlayer = value;
  }
  //Flag var to hide and show auction ui
  public get returnToAuctionFlagGet(): boolean {
    return this._returnToAuctionFlag;
  }
  public set returnToAuctionFlagSet(value: boolean) {
    this._returnToAuctionFlag = value;
  }
  //Team data 
  public get teamDataGet(): any {
    return this._teamData;
  }
  public set teamDataSet(value: any) {
    this._teamData = value;
  }
  public get auctionDetailGet(): any {
    return this._auctionDetail;
  }
  public set auctionDetailSet(value: any) {
    this._auctionDetail = value;
    this.setLocal('Admin', 'auctionObj', value);
  }

  public get tournamentDetailGet(): any {
    return this._tournamentDetail;
  }
  public set tournamentDetailSet(value: any) {
    this._tournamentDetail = value;
    this.setLocal('Admin', 'tourObj', value);
  }

  private auctionDetailObj() {
    let auctionObj = this.getLocal('Admin', 'auctionObj');
    if (auctionObj != undefined) {
      this._auctionDetail = auctionObj;
    } else {
      this._auctionDetail = {};
    }
  }

  private tournamentDetailObj() {
    let tourObj = this.getLocal('Admin', 'tourObj');
    if (tourObj != undefined) {
      this._tournamentDetail = tourObj;
    } else {
      this._tournamentDetail = {};
    }
  }

  //clear DataService
  clearDataService() {
    this._tournamentDetail = {};
    this._auctionDetail = {};
    this._teamData = {};
    this._allTeamPlayerListMap = {};
    this._inviteePlayerListMap = {};
    this._lastTimeInvitee = null;
    this._lastTimeTeamPlayer = {};
  }

  setLocal(module: string, key: string, data: any) {
    // this.removeLocal(module , key);
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
    }
    modObj[key] = data;
    localStorage.setItem(module, JSON.stringify(modObj));
  }

  getLocal(module: string, key: string) {
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
      localStorage.setItem(module, JSON.stringify(modObj));
    }
    if (key != undefined) return modObj[key];
    return modObj;
  }

  removeLocal(module: string, key: string) {
    if (key != undefined) {
      let modObj = JSON.parse(localStorage.getItem(module));
      if (modObj != undefined) {
        delete modObj[key];
        localStorage.setItem(module, JSON.stringify(modObj));
        return;
      }
    }
    localStorage.removeItem(module);
  }

  ngOnDestroy() {
    console.log('Service destroy')
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
