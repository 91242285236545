import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { AuthServiceService } from '../service/auth-service.service';
import { formatDate } from '@angular/common';
import { DataServiceClass } from 'src/app/service/DataService.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public showMessages: any = {};
  public registration: FormGroup;
  public user: any = {};
  public submitted: boolean = false;
  constructor(@Inject(LOCALE_ID) public locale: string,
    private authServiceService: AuthServiceService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dataServiceClass: DataServiceClass) {
    this.user = this.authServiceService.currentUserDataValue;
    if (this.user) {
      this.user['dob'] = formatDate(this.user.dob, 'yyyy-MM-dd', this.locale);
    }
  }

  ngOnInit() {
    this.registration = this.formBuilder.group({
      userId: [null],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(10)]],
      dob: [null, Validators.required],
      password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]{4}$')]],
      cpassword: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]{4}$')]],
    });
    if (this.user) this.registration.patchValue(this.user);
  }

  get registrationFormControls() {
    return this.registration.controls;
  }

  get isDisabled() {
    return this.submitted || this.registration.invalid || this.registrationFormControls.password.value && this.registrationFormControls.cpassword.value !== this.registrationFormControls.password.value
  }

  register() {
    this.registration.markAllAsTouched();
    if (this.registration.valid) {
      localStorage.setItem('userData', JSON.stringify(this.registration.value));
      if (this.dataServiceClass.userDataGet && this.dataServiceClass.userDataGet.payment) {
        return this.router.navigate(['/payment/register']);
      }
      this.submitted = true;
      this.authServiceService.register(this.registration.value).subscribe(res => {
        if (res && res.status === "Success") {
          this.dataServiceClass.userMobileNumberSet = res.data.phone;
          this.dataServiceClass.userEncryptedIdSet = res.data.uuid;
          this.router.navigate(['/auth/login-otp']);
          this.submitted = false;
        }
      })
    }
  }

}
