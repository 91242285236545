<img src="assets/images/Procric8.png" alt="Procric8" class="mx-auto d-block mb-5 procric8Img" width="230">
<form aria-labelledby="title" [formGroup]="loginForm">
    <div class="back-to-login">{{this.dataServiceClass.userMobileNumberGet}} <span routerLink="../login">Change</span>
    </div>
    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">Enter 4 Digit Passcode:</label>
        </span>
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    </div>
    <!-- <button nbButton outline shape="round" fullWidth status="primary" size="small" [class.btn-pulse]="submitted">
    Log In
  </button> -->
    <section class="another-action mt-4" aria-label="Register">
        <button nbButton outline shape="round" status="primary" (click)="onSubmit()">Login</button>
    </section>
    <!-- <section class="another-action mt-2" aria-label="Register">
        <a class="text-link" (click)="forgotPassword()">Forgot Password ?</a>
    </section>
    <section class="another-action mt-2" aria-label="Register">
        <h2 class="hr-line"><span>Or</span></h2>
    </section> -->
    <!-- <section class="another-action mt-4" aria-label="Register">
        <button nbButton outline shape="round" status="info" (click)="loginWithOtp()">Login with OTP</button>
    </section> -->
</form>