<img src="assets/images/Procric8.png" alt="Procric8" class="mx-auto d-block mb-5 procric8Img" width="230">
<form (ngSubmit)="onVerifyUsername()" [formGroup]="verifyForm" aria-labelledby="title">
    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">Mobile / Email:</label>
        </span>
        <input type="tel" nbInput fullWidth formControlName="username" name="username" id="input-password"
            placeholder="Enter Your Username" fieldSize="large" [required]="true">
        <ng-container *ngIf="verifyFormControl['username'].invalid && verifyFormControl['username'].touched ">
            <p class="caption status-danger" *ngIf="verifyFormControl['username'].errors?.required">
                Username is required!
            </p>
            <p class="caption status-danger" *ngIf="verifyFormControl['username'].errors?.pattern">
                Please enter valid email address or phone number
            </p>
        </ng-container>
    </div>
    <button nbButton fullWidth status="primary" size="large" [disabled]="!verifyFormControl['username'].valid">
        Continue
    </button>
    <!-- <section class="another-action" aria-label="Register">
        Don't have an account? <a class="text-link" routerLink="../register">Register</a>
    </section> -->
</form>