import {
  ModuleWithProviders,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCheckboxModule,
  NbCardModule,
  NbTabsetModule,
  NbStepperModule,
  NbDatepickerModule,
  NbRouteTabsetModule,
  NbListModule,
  NbWindowModule,
  NbInputModule,
  NbAlertModule,
} from "@nebular/theme";
import { NbEvaIconsModule } from "@nebular/eva-icons";
// import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from "./components";
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from "./pipes";
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from "./layouts";
import { DEFAULT_THEME } from "./styles/theme.default";
import { COSMIC_THEME } from "./styles/theme.cosmic";
import { CORPORATE_THEME } from "./styles/theme.corporate";
import { DARK_THEME } from "./styles/theme.dark";
import { OnlyNumber } from "./directives/fixedNumber";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { TableSearchPipe } from "./pipes/table-search.pipe";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgPipesModule } from "ngx-pipes";
const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  // NbSecurityModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbInputModule,
  NbCheckboxModule,
  NbEvaIconsModule,
  NbStepperModule,
  NbDatepickerModule,
  NbCardModule,
  NbWindowModule,
  NbListModule,
  NbAlertModule,
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  TableSearchPipe,
];

@NgModule({
  imports: [
    CommonModule,
    NgPipesModule,
    ...NB_MODULES,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CommonModule, ...PIPES, OnlyNumber, ...COMPONENTS, ...NB_MODULES],
  declarations: [...COMPONENTS, ...PIPES, OnlyNumber, UserProfileComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: "dark",
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
