import { AlertService } from './../../service/alert.service';
import { Router } from "@angular/router";
import { AuthServiceService } from "src/app/auth/service/auth-service.service";
import { DataServiceClass } from "./../../service/DataService.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MustMatch } from "../validator/must-match.validator";
import { first } from "rxjs/operators";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild("ngOtpInput", { static: true }) ngOtpInput: any;
  public forgotPasswordForm: FormGroup;
  public otpSentTo: any;
  public otp_submitted: boolean = false;
  public otpConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: "",
    inputStyles: {
      width: "30px",
      height: "50px"
    }
  };
  public passwordConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: true,
    placeholder: "",
    inputStyles: {
      width: "40px",
      height: "50px"
    }
  };
  constructor(
    private formGroup: FormBuilder,
    public dataServiceClass: DataServiceClass,
    private authService: AuthServiceService,
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.otpSentTo = this.dataServiceClass.userMobileNumberGet;
    this.initForgotPasswordForm();
  }

  initForgotPasswordForm() {
    this.forgotPasswordForm = this.formGroup.group(
      {
        username: [""],
        otp: ["", Validators.required],
        password: ["", Validators.required],
        confirm_password: ["", Validators.required]
      },
      {
        validator: MustMatch("password", "confirm_password")
      }
    );
  }

  get forgotPasswordFormValue() {
    return this.forgotPasswordForm.controls;
  }

  getOTP(otpValue) {
    if (otpValue.length == 6) {
      this.forgotPasswordFormValue.otp.setValue(otpValue);
    }
  }

  getPassword(passwordValue) {
    if (passwordValue.length == 4) {
      this.forgotPasswordFormValue.password.setValue(passwordValue);
    }
  }

  getConfirmPassword(confirmPassValue) {
    if (confirmPassValue.length == 4) {
      this.forgotPasswordFormValue.confirm_password.setValue(confirmPassValue);
    }
  }

  onResetPassword() {
    this.forgotPasswordFormValue.username.setValue(this.dataServiceClass.userMobileNumberGet);
    this.otp_submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.authService
      .resetPassword(this.forgotPasswordForm.value)
      .pipe(first())
      .subscribe(response => {
        if (response.status == 200) {
          this.router.navigate(["auth/login-passcode"]);
        }
      });
  }

  resendOtp() {
    this.authService.forgotPassword({ "username": this.dataServiceClass.userMobileNumberGet }).pipe(first()).subscribe(response => {
      if (response) {
        this.alertService.showToast('success', 'Resend Otp')
      }
    })
  }
}
