import { AlertService } from "./../../service/alert.service";
import { DataServiceClass } from "./../../service/DataService.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthServiceService } from "../service/auth-service.service";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";

@Component({
  selector: "app-login-otp",
  templateUrl: "./login-otp.component.html",
  styleUrls: ["./login-otp.component.scss"],
})
export class LoginOtpComponent implements OnInit {
  @ViewChild("ngOtpInput", { static: true }) ngOtpInput: any;
  public loginOtpForm: FormGroup;
  public otpSentTo: any;
  public user: any = {};
  public config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "40px",
      height: "50px",
    },
  };
  constructor(
    private formGroup: FormBuilder,
    private dataServiceClass: DataServiceClass,
    private authService: AuthServiceService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.user = this.authService.currentUserDataValue;
    this.otpSentTo =
      this.dataServiceClass.userMobileNumberGet ||
      (this.user && this.user.phone);
    this.loginOtpForm = this.formGroup.group({
      otp: [""],
    });
  }

  get value() {
    return this.loginOtpForm.controls;
  }

  onOtpChange(otpValue) {
    if (otpValue.length == 4) {
      this.value.otp.setValue(otpValue);
    }
  }

  onLoginOtp() {
    this.authService
      .loginWithOtp(this.value.otp.value)
      .pipe(first())
      .subscribe((data) => {
        if (data) {
          this.alertService.showToast("success", "Login Successfully");
          this.router.navigate(["/procric8"]);
        }
      });
  }

  resendOtp() {
    this.authService
      .getLoginOtp()
      .pipe(first())
      .subscribe((response) => {
        if (response) {
          this.alertService.showToast("success", "Resend Otp");
        }
      });
  }
}
