<img src="assets/images/Procric8.png" alt="Procric8" class="mx-auto d-block mb-2 procric8Img" width="230">
<form aria-labelledby="title" [formGroup]="forgotPasswordForm" (ngSubmit)="onResetPassword()">
    <div>
        <div class="form-control-group">
            <span class="label-with-link">
                <label class="label" for="input-password">Enter the 6 digit OTP sent to : {{dataServiceClass.userMobileNumberGet}}</label>
            </span>
            <ng-otp-input (onInputChange)="getOTP($event)" [config]="otpConfig"></ng-otp-input>
            <ng-container *ngIf="otp_submitted && forgotPasswordFormValue['otp'].invalid">
                <p class="caption status-danger" *ngIf="forgotPasswordFormValue['otp'].errors.required">
                    Otp field is required
                </p>
            </ng-container>
        </div>
        <!-- <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="submitted">
        Login
      </button> -->
    </div>
    <div class="new-password">
        <div class="form-control-group">
            <span class="label-with-link mb-1">
                <label class="label" for="input-password">New PassCode:</label>
            </span>
            <ng-otp-input (onInputChange)="getPassword($event)" [config]="passwordConfig"></ng-otp-input>
            <!-- <input nbInput fullWidth OnlyNumber="true" formControlName="password" name="password" type="text" id="input-password" placeholder="Enter Password" fieldSize="large" [required]="true" [minlength]="4" [maxlength]="4">-->
            <ng-container *ngIf="otp_submitted && forgotPasswordFormValue['password'].invalid">
                <p class="caption status-danger" *ngIf="forgotPasswordFormValue['password'].errors.required">
                    Password is required!
                </p>
            </ng-container>
        </div>
        <div class="form-control-group">
            <span class="label-with-link mb-1">
                <label class="label" for="input-password">Confirm PassCode:</label>
            </span>
            <ng-otp-input (onInputChange)="getConfirmPassword($event)" [config]="passwordConfig"></ng-otp-input>
            <!-- <input nbInput fullWidth OnlyNumber="true" formControlName="confirm_password" name="confirm_password" type="text" id="input-password" placeholder="Enter Confirm Password" fieldSize="large" [required]="true" [minlength]="4" [maxlength]="4">  -->
            <ng-container *ngIf="otp_submitted && forgotPasswordFormValue['confirm_password'].invalid">
                <p class="caption status-danger" *ngIf="forgotPasswordFormValue['confirm_password'].errors.required">
                    Confirm Password is required!
                </p>
                <p class="caption status-danger" *ngIf="forgotPasswordFormValue['confirm_password'].errors.mustMatch">
                    Confirm Password is not match!
                </p>
            </ng-container>
        </div>
        <section class="another-action mt-4" aria-label="Register">
            <button nbButton outline shape="round" status="info" type="submit">Reset Password</button>
        </section>
        <section class="another-action mt-4" aria-label="Register">
            <a class="text-link" (click)="resendOtp()">Resend OTP</a>
        </section>
    </div>
</form>