import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthServiceService } from '../auth/service/auth-service.service';
import { AlertService } from '../service/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthServiceService, private alert: AlertService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err)
            if (err.status === 403) {
                console.log(err)
                this.alert.showToast('danger', 'Validation Error', err.error.message)
                // auto logout if 401 response returned from api
                // this.authenticationService.logout();
                // location.reload(true);
            } else if (err.status === 500) {
                console.log(err)
                this.alert.showToast('danger', 'Validation Error', 'Internal Server Error')
                // auto logout if 401 response returned from api
                // this.authenticationService.logout();
                // location.reload(true);
            }
            else if (err.status === 400 || err.status === 401) {
                console.log(err)
                this.alert.showToast('danger', 'Validation Error', err.error.status)
                // auto logout if 401 response returned from api
                // this.authenticationService.logout();
                // location.reload(true);
            }

            const error = err.error && (err.error.message || err.statusText);
            return throwError(error);
        }))
    }
}