import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
  ) { }

  postData(payload, requrl): Observable<any> {
    const url = `${requrl}`;
    return this.http.post<any>(url, payload);
  }

  getData(requrl) {
    const url = `${requrl}`;
    return this.http.get<any>(url);
  }

  getDataWithParams(obj, requrl) {
    const url = `${requrl}`;
    return this.http.get<any>(url, { params: obj });
  }

  putData(obj, requrl) {
    const url = `${requrl}`;
    return this.http.put<any>(url, obj);
  }

  deleteData(requrl) {
    const url = `${requrl}`;
    return this.http.delete<any>(url);
  }

}
