<h1 id="title" class="title">Register</h1>
<form (ngSubmit)="register()" [formGroup]="registration" aria-labelledby="title">
    <div class="row">
        <div class="col-md-6 col-lg-6 col-12">
            <div class="form-control-group">
                <label class="label" for="input-name">First name:</label>
                <input nbInput formControlName="firstname" id="First-name" name="firstname" placeholder="First name"
                    autofocus fullWidth fieldSize="large" [required]="true">
                <ng-container
                    *ngIf="registrationFormControls.firstname.invalid && registrationFormControls.firstname.touched">
                    <p class="caption status-danger" *ngIf="registrationFormControls.firstname.errors?.required">
                        First Name is required!
                    </p>
                </ng-container>
            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-12">
            <div class="form-control-group">
                <label class="label" for="input-name">Last name:</label>
                <input nbInput formControlName="lastname" id="Last-name" name="lastname" placeholder="Last name"
                    autofocus fullWidth fieldSize="large" [required]="true">
                <ng-container
                    *ngIf="registrationFormControls.lastname.invalid && registrationFormControls.lastname.touched">
                    <p class="caption status-danger" *ngIf="registrationFormControls.lastname.errors?.required">
                        Last Name is required!
                    </p>
                </ng-container>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12 col-lg-12 col-12">
            <div class="form-control-group">
                <label class="label" for="input-email">Email address:</label>
                <input nbInput formControlName="email" id="input-email" name="email" pattern=".+@.+..+"
                    placeholder="Email address" fullWidth fieldSize="large" [required]="true">
                <ng-container *ngIf="registrationFormControls.email.invalid && registrationFormControls.email.touched">
                    <p class="caption status-danger" *ngIf="registrationFormControls.email.errors?.required">
                        Email is required!
                    </p>
                    <p class="caption status-danger" *ngIf="registrationFormControls.email.errors?.pattern">
                        Email should be the real one!
                    </p>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-6 col-12">
            <div class="form-control-group">
                <label class="label" for="input-name">Phone Number:</label>
                <input nbInput OnlyNumber="true" formControlName="phone" id="phone-number" name="phone"
                    placeholder="Phone Number" autofocus fullWidth fieldSize="large" [required]="true" minlength="8"
                    maxlength="10">
                <ng-container *ngIf="registrationFormControls.phone.invalid && registrationFormControls.phone.touched">
                    <p class="caption status-danger" *ngIf="registrationFormControls.phone.errors?.required">
                        Phone number is required!
                    </p>
                    <p class="caption status-danger"
                        *ngIf="registrationFormControls.phone.errors?.minlength || registrationFormControls.phone.errors?.maxlength">
                        Phone number is not valid!
                    </p>
                </ng-container>
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-12">
            <div class="form-control-group">
                <label class="label" for="input-name">Date of birth:</label>
                <input nbInput type="date" formControlName="dob" id="dob" name="dob" placeholder="Dob" autofocus
                    fullWidth fieldSize="large" [required]="true">
                <ng-container *ngIf="registrationFormControls.dob.invalid && registrationFormControls.dob.touched">
                    <p class="caption status-danger" *ngIf="registrationFormControls.dob.errors?.required">
                        Date of Birth is required!
                    </p>
                </ng-container>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-6 col-lg-6 col-12">
            <div class="form-control-group">
                <label class="label" for="input-password">Password:</label>
                <input nbInput OnlyNumber="true" formControlName="password" type="password" inputmode="numeric"
                    [pattern]="'^[0-9]{4}$'" id="input-password" name="password" placeholder="Password" fullWidth
                    fieldSize="large" [required]="true" minlength="4" maxlength="4">
                <ng-container
                    *ngIf="registrationFormControls.password.invalid && registrationFormControls.password.touched">
                    <p class="caption status-danger" *ngIf="registrationFormControls.password.errors?.required">
                        Password is required!
                    </p>
                    <p class="caption status-danger"
                        *ngIf="registrationFormControls.password.errors?.minlength || registrationFormControls.password.errors?.maxlength">
                        Password should contain from characters
                    </p>
                </ng-container>
            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-12">
            <div class="form-control-group">
                <label class="label" for="input-password">Confirm Password:</label>
                <input nbInput OnlyNumber="true" formControlName="cpassword" type="password" inputmode="numeric"
                    [pattern]="'^[0-9]{4}$'" id="input-c_password" name="cpassword" placeholder="Confirm Password"
                    fullWidth fieldSize="large" [required]="true" minlength="4" maxlength="4">
                <ng-container
                    *ngIf="registrationFormControls.cpassword.invalid && registrationFormControls.cpassword.touched">
                    <p class="caption status-danger" *ngIf="registrationFormControls.cpassword.errors?.required">
                        Confirm Password is required!
                    </p>
                    <p class="caption status-danger"
                        *ngIf="registrationFormControls.cpassword.errors?.minlength || registrationFormControls.cpassword.errors?.maxlength">
                        Confirm Password should be number only!
                    </p>
                </ng-container>
                <ng-container
                    *ngIf="registrationFormControls.cpassword.value !== registrationFormControls.password.value">
                    <p class="caption status-danger">
                        Password and Confirm Password is not same!
                    </p>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- <div class="form-control-group accept-group" *ngIf="getConfigValue('forms.register.terms')">
        <nb-checkbox name="terms" [(ngModel)]="user.terms" [required]="getConfigValue('forms.register.terms')">
            Agree to <a href="#" target="_blank"><strong>Terms & Conditions</strong></a>
        </nb-checkbox>
    </div> -->

    <button nbButton fullWidth status="primary" size="large" [disabled]="isDisabled" [class.btn-pulse]="submitted">
        Register
    </button>
</form>

<section class="another-action" aria-label="Sign in">
    Already have an account? <a class="text-link" routerLink="../login">Log in</a>
</section>