<img src="assets/images/Procric8.png" alt="Procric8" class="mx-auto d-block mb-5 procric8Img" width="230">
<form aria-labelledby="title" [formGroup]="loginOtpForm" (ngSubmit)="onLoginOtp()">
    <div class="form-control-group">
        <span class="label-with-link">
      <label class="label" for="input-password">Enter the 6 digit OTP sent to : {{otpSentTo}}</label>
      <!-- <a class="forgot-password caption-2" routerLink="../request-password">Forgot Password?</a> -->
    </span>
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    </div>
    <section class="another-action mt-4" aria-label="Register">
        <button nbButton outline shape="round" status="info" type="submit">Login</button>
    </section>
    <section class="another-action mt-4" aria-label="Register">
        <a class="text-link" (click)="resendOtp()">Resend OTP</a>
    </section>
</form>