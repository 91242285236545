import { AlertService } from "./../../service/alert.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthServiceService } from "src/app/auth/service/auth-service.service";
import { DataServiceClass } from "src/app/service/DataService.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { first } from "rxjs/operators";

@Component({
  selector: "app-login-passcode",
  templateUrl: "./login-passcode.component.html",
  styleUrls: ["./login-passcode.component.scss"],
})
export class LoginPasscodeComponent implements OnInit {
  public loginForm: FormGroup;
  @ViewChild("ngOtpInput", { static: true }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "50px",
      height: "50px",
    },
  };

  constructor(
    private formGroup: FormBuilder,
    public dataServiceClass: DataServiceClass,
    private authService: AuthServiceService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loginForm = this.formGroup.group({
      password: [""],
      uuid: [""],
    });
    if (
      !this.dataServiceClass.userEncryptedIdGet &&
      !this.dataServiceClass.userMobileNumberGet
    ) {
      this.router.navigate(["/auth/login"]);
    } else {
      this.loginForm.controls.uuid.setValue(
        this.dataServiceClass.userEncryptedIdGet
      );
    }
  }
  get loginFormValue() {
    return this.loginForm.controls;
  }
  onOtpChange(otp) {
    this.loginFormValue.password.setValue(otp);
  }

  onSubmit() {
    this.authService
      .login(this.loginForm.value)
      .pipe(first())
      .subscribe((response) => {
        if (response.status == "Success") {
          this.alertService.showToast("success", "Login Successfully");
          this.router.navigate(["/procric8/user-profile"]);
        }
      });
  }

  forgotPassword() {
    this.authService
      .forgotPassword({ username: this.loginForm.value.username })
      .pipe(first())
      .subscribe((response) => {
        if (response) {
          this.router.navigate(["auth/forgot-password"]);
          this.alertService.showToast("success", "OTP Sent");
        }
      });
  }

  loginWithOtp() {
    this.authService
      .getLoginOtp()
      .pipe(first())
      .subscribe((response) => {
        if (response) {
          this.router.navigate(["auth/login-otp"]);
          this.alertService.showToast("success", "OTP Sent");
        }
      });
  }
}
