import { AlertService } from "./../../service/alert.service";
import { DataServiceClass } from "src/app/service/DataService.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthServiceService } from "../service/auth-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { first } from "rxjs/operators";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public submitted: boolean = false;
  public showPasscode: boolean = false;
  public verifyForm: FormGroup;
  public inviteParam: any;
  constructor(
    private authService: AuthServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private dataServiceClass: DataServiceClass,
    private alertService: AlertService
  ) {
    route.queryParamMap.subscribe((params: any) => {
      if (params.params.link) {
        this.inviteParam = params.params;
      }
    });
  }

  returnUrl: string;

  private getInviteData() {
    if (this.inviteParam === undefined) return;
    this.authService.inviteLink(this.inviteParam.link).subscribe((response) => {
      this.inviteParam.userId
        ? (response.data.userId = +this.inviteParam.userId)
        : null;
      if (response && response.data) {
        this.dataServiceClass.userMobileNumberSet = response.data.phone;
        this.dataServiceClass.userEncryptedIdSet = response.data.uuid;
        this.dataServiceClass.userDataSet = response.data;
        if (this.dataServiceClass.userMobileNumberGet != null) {
          this.verifyFormControl.username.setValue(
            this.dataServiceClass.userMobileNumberGet
          );
        }
        if (response && response.data.register) {
          this.router.navigate(["auth/register"]);
        } else if (
          response &&
          !response.data.register &&
          response.data.payment
        ) {
          this.router.navigate(["payment/register"]);
        } else {
          this.router.navigate(["auth/login-passcode"]);
        }
      }
    });
  }

  ngOnInit() {
    this.verifyForm = this.formBuilder.group({
      username: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
          ),
        ],
      ],
    });
    this.getInviteData();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "procric8";
  }

  get verifyFormControl() {
    return this.verifyForm.controls;
  }

  onVerifyUsername() {
    this.authService
      .verifyUsername(this.verifyFormControl.username.value)
      .pipe(first())
      .subscribe((response) => {
        this.dataServiceClass.userEncryptedIdSet = response.data.uuid;
        this.dataServiceClass.userMobileNumberSet =
          this.verifyForm.value.username;
        if (
          response &&
          response.status === "Success" &&
          response.data.validIdentity
        ) {
          this.alertService.showToast("success", "Verified Successfully");
          this.router.navigate(["auth/login-passcode"]);
        } else {
          this.alertService.showToast(
            "danger",
            "Validation Error",
            "User Not Exist!"
          );
        }
      });
  }
}
